<template>
  <section class="relative">
    <div v-for="(item, idx) in categories" :key="idx">
      <div
        class="masonry-3-col p-3"
        v-if="
          idx === activeCategoryIndex ||
          (firstIndexFocused === true && idx === 0)
        "
      >
        <div
          v-for="category in item.children"
          class="flex flex-col gap-3 mb-8 break-inside"
        >
          <div
            class="font-lato text-gray-900 text-base hover:text-secondary-400 cursor-pointer"
          >
            <nuxt-link :to="`/catalog/${category.id}`">{{
              category.label
            }}</nuxt-link>
          </div>
          <div class="flex flex-col gap-2">
            <div
              v-for="(subcategory, idx) in category.children"
              :key="idx"
              class="text-sm font-lato text-gray-600"
            >
              <div
                class="flex flex-row gap-4 flex-wrap"
                @mouseover="hoverSubcategory(idx)"
                @mouseleave="isHoverSubcategory = false"
              >
                <nuxt-link
                  class="hover:text-secondary-400 cursor-pointer z-0"
                  :to="`/catalog/${subcategory.id}`"
                >
                  {{ subcategory.label }}
                  {{ subcategory.amount }}
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  categories: {
    type: Object,
  },
  isActive: Boolean,
  activeCategoryIndex: Number,
});
const activeSubcategoryIndex = ref(null);
const isHover = ref(false);
const isHoverSubcategory = ref(false);

const firstIndexFocused = computed(() => {
  if (isHover.value) {
    return false;
  } else if (props.isActive) {
    return true;
  } else return false;
});

function hoverSubcategory(idx) {
  activeSubcategoryIndex.value = idx;
  isHoverSubcategory.value = true;
}
</script>

<style scoped>
.masonry-3-col {
  column-count: 3;
  column-gap: 1em;
}

.break-inside {
  break-inside: avoid;
}
</style>
